'use client';

import { Heading } from 'react-aria-components';
import { Button } from '#/packages/ui';
import AuthenticationLayout from '../components/auth/AuthenticationLayout';

export default function NotFound() {
  return (
    <AuthenticationLayout>
      <div className='items-center mx-auto flex flex-col text-center max-w-60'>
        <div className='mt-10'>
          <Heading className='text-2.5xl font-semibold'>Page not found</Heading>
        </div>
        <div className='my-3'>
          <p className='text-xs text-gray-750 leading-normal font-medium'>
            The page you are looking for has been removed, renamed or unavailable.
          </p>
        </div>
        <Button
          size='xl'
          wFull
          justify='center'
          flex
          color={'secondary'}
          href='/'
          className='mt-5 mb-5'
        >
          Back to Homepage
        </Button>
      </div>
    </AuthenticationLayout>
  );
}
